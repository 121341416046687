<template>
  <div @click="expand = !expand" v-click-outside="deselect">
    <!-- Item Header -->
    <div
        :style="!isLast || expand ? 'border-bottom : 1px solid var(--wsBACKGROUND)' : ''"
        class="d-flex justify-space-between align-center py-3 px-4 pointer"
    >
      <!-- Item Content -->
      <div class="d-flex align-center">

        <!-- Icon -->
        <v-icon class="mr-4" :color="wsACCENT">{{  icon  }}</v-icon>
        <!-- Content -->
        <div style="width: 100%">
          <!-- Title -->
          <h5 class="wsDARKER">
            {{ $t(title) }}
          </h5>
          <!-- Subtitle -->
          <h5 v-if="!arrayFiltered.length" class="wsDARKLIGHT font-weight-regular mt-1">
            {{ $t(subtitle) }}
          </h5>
          <!-- Selected Icons -->
          <div class="d-flex align-center flex-wrap">
            <v-sheet
                v-for="item in arrayFiltered" :key="item"
                :color="wsLIGHTCARD"
                class="d-flex px-2 py-1 mr-2 mt-2"
                style="border-radius: 2px"
            >
              <h5 style="font-size: 12px" class="wsACCENT text-no-wrap font-weight-regular">
                {{  getItemName(item)  }}
              </h5>
              <v-hover #default="{hover}">
                <v-icon
                    @click.stop="deselectItem(item)"
                    :style="hover ? `background-color: var(--wsBACKGROUND);` : ''"
                    class="ml-1 pointer"
                    style="border-radius: 2px"
                    small
                    :color="wsACCENT">
                  mdi-close
                </v-icon>
              </v-hover>
              </v-sheet>
          </div>

        </div>
      </div>

      <!-- Expand Icon-->
      <v-icon :color="wsACCENT">
        mdi-chevron-{{ expand ? 'up' : 'down' }}
      </v-icon>


    </div>
    <!-- Items Selector -->
    <v-expand-transition>
      <div @click.stop v-if="expand"
           style="max-height : 300px;  border-bottom : 1px solid var(--wsBACKGROUND);"
           class="overflow-y-auto"
           :class="[{'pt-4' : items.length  > 5}]"
      >
        <!-- Search Field -->
        <ws-text-field
            v-if="items.length  > 5"
            v-model="search"
            :placeholder="$t(searchPlaceholder)"
            :class="[{'mb-4' : itemsFiltered.length > 0}]"
            icon="mdi-magnify"
            class="mx-4"
            clearable
        />

        <!-- Selector Items -->
        <v-hover
            v-for="(item,i) in itemsFiltered" :key="i"
            #default="{hover}"
        >
          <v-sheet
              :color="hover ? wsLIGHTCARD : 'transparent'"
              class="pointer d-flex align-center justify-space-between px-4"
              v-ripple
              @click="selectItem(item.value)"
              height="48"
          >
            <h5
                class="font-weight-regular wsDARKER "
            >
              {{ item.text }}
            </h5>

            <v-icon v-if="array.includes(item.value) || selected === item.value" :color="wsACCENT">mdi-check</v-icon>
          </v-sheet>
        </v-hover>

        <!-- No Items Placeholders -->
        <div v-if="!itemsFiltered.length" class="pa-4">
          <h5 class="wsACCENT font-weight-regular text-center">{{ $t(!search ? noItemsText : noSearchItemsText) }}</h5>
        </div>

      </div>
    </v-expand-transition>

  </div>

</template>

<script>
export default {
  name: "emailReceiversBasicComponent",
  props : {
    value : {},
    single : {
      type : Boolean
    },
    title : {
      type : String,
      default : 'Title'
    },
    subtitle : {
      type : String,
      default : 'Subtitle'
    },
    searchPlaceholder : {
      type : String,
      default : 'Search'
    },
    noItemsText : {
      type : String,
      default : 'NoItemsFound'
    },
    noSearchItemsText : {
      type : String,
      default : 'NoSearchItemsFound'
    },
    items : {
      type : Array,
      default () { return [] }
    },
    icon : {
      type : String,
      default : 'mdi-cog'
    },
    isLast : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selected : null,
      array : [],
      expand : false,
      search : null
    }
  },
  watch : {
    value : {
      handler(value) {
        if (!this.EQ(value , this.array)) {
          this.prepareValue()
        }
      }
    }
  },
  computed : {
    arrayFiltered() {
      if ( this.single) {
        return this.selected?[this.selected] : []
      }
      return this.array
    },

    itemsFiltered() {
      let items = this.items
      if (this.search) {
        items = items.filter(el => el.text.toLowerCase().trim().includes(this.search.toLowerCase().trim() ) )
      }
      return items
    }
  },
  methods : {
    getItemName (value) {
      let item = this.items.find(item => item.value === value)

      let text = item ? item.text : item

      if (text.length > 23) {
        text = text.slice(0,23) + "..."
      }

      return text
    },
    deselectItem(itemValue) {
      this.expand = false
      if (this.single) {
        this.selected = null
        this.$emit('input' , null)
        return
      }
      this.array = this.array.filter(el => el !== itemValue)
      this.$emit('input', this.array)
    },
    selectItem(itemValue) {
      if (this.single) {
        this.selected = itemValue
        this.$emit('input', this.selected)
        this.expand = false
        return
      }

      if (this.array.includes(itemValue)) {
        this.array = this.array.filter(el => el !== itemValue)
      } else {
        this.array.push(itemValue)
      }
      this.$emit('input', this.array)
    },
    deselect() {
      this.expand = false
    },

    prepareValue() {
      if (this.single) {
        this.selected = this.value
        return
      }
      this.array = this.COPY(this.value)
    }
  },
  mounted() {
    if ( this.value) {
      this.prepareValue()
    }
  }
}
</script>

<style scoped>
.input {
  width:100% ;
  line-height: 1;
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: var(--wsACCENT);
  outline : none
}
.input::placeholder {
  color : var(--wsDARKLIGHT) !important
}
</style>